<template>
  <div style="text-align: left">用户列表</div>
  <el-form
    :inline="true"
    :model="seachForm"
    style="margin-top: 20px; display: flex; justify-content: flex-start"
    class="lineform"
  >
    <el-form-item label="用户名称">
      <el-input
        v-model="seachForm.userName"
        clearable
        size="small"
        placeholder="请输入用户名称"
      ></el-input>
    </el-form-item>
    <el-form-item label="手机号" style="margin-left: 20px">
      <el-input
        v-model="seachForm.cellphone"
        clearable
        size="small"
        placeholder="请输入手机号"
      ></el-input>
    </el-form-item>
    <el-form-item label="用户类型" style="margin-left: 20px">
      <el-select
        v-model="seachForm.userType"
        clearable
        placeholder="请选择"
        style="width: 200px"
      >
        <el-option
          v-for="item in option"
          :key="item.type"
          :label="item.value"
          :value="item.name"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button
        type="primary"
        @click="onSubmit"
        size="mini"
        style="margin-left: 20px"
        >查询</el-button
      >
      <el-button
        type="primary"
        @click="clearall"
        size="mini"
        style="margin-left: 20px"
        >清空</el-button
      >
    </el-form-item>
  </el-form>
  <div style="display: flex; justify-content: flex-start">
    <el-button type="primary" size="mini" @click="adduser">新增用户</el-button>
  </div>
  <el-table :data="tableData" stripe style="width: 100%; margin-top: 30px">
    <el-table-column prop="loginId" label="登录名" width="180">
    </el-table-column>
    <el-table-column prop="userName" label="名称" width="180">
    </el-table-column>
    <el-table-column prop="userTypeVal" label="用户类型" width="180">
      <template #default="scope">
        <el-tag v-if="scope.row.userType === 'ADMINUSER'" type="danger">{{
          scope.row.userTypeVal
        }}</el-tag>
        <el-tag v-else-if="scope.row.userType === 'TECHUSER'" type="success">{{
          scope.row.userTypeVal
        }}</el-tag>
        <el-tag v-else-if="scope.row.userType === 'FINUSER'" type="warning">{{
          scope.row.userTypeVal
        }}</el-tag>
        <el-tag v-else-if="scope.row.userType === 'OPERATOR'" type="info">{{
          scope.row.userTypeVal
        }}</el-tag>
      </template>
    </el-table-column>
    <el-table-column prop="cellphone" label="联系方式" width="180">
    </el-table-column>
    <el-table-column prop="createDate" label="创建日期" width="180">
    </el-table-column>
    <el-table-column label="操作">
      <template #default="scope">
        <div
          style="display: flex; justify-content: flex-start; flex-wrap: wrap"
        >
          <el-button
            type="text"
            @click="edituser(scope.row)"
            style="margin-right: 10px"
            >修改信息</el-button
          >
          <el-button
            type="text"
            @click="resetpassword(scope.row)"
            style="margin-right: 10px"
            >重置密码</el-button
          >
          <el-button
            type="text"
            style="margin-left: 10px !important"
            @click="deletegood(scope.row)"
            >删除</el-button
          >
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
export default {
  data() {
    return {
      tableData: [
      ],
      seachForm: {
        userName: '',
        cellphone: '',
        userType: ''
      },
      option: []
    }
  },
  created() {
    const Arr = [
      {
        name: '首页',
        index: '/index'
      },
      {
        name: '系统管理',
        index: '/systommange'
      },
      {
        name: '用户列表',
        index: ''
      }]
    this.$store.commit('setBreadInfo', Arr)
    this.getUserList()
    this.getUserType()
  },
  methods: {
    edituser(item) {
      this.$store.commit('setUserInfo', item)
      this.$router.push('/adduser/false')
    },
    async resetpassword(row) {
      console.log(row)
      const res = await this.$http.put('/nktdec/cruserinfo/password/' + row.id)
      console.log(res)
      this.$alert('该用户密码为' + res.data.result, '编辑成功', {
        confirmButtonText: '确定',
        callback: () => {
          this.$router.push('/systommange')
        }
      })
    },
    deletegood(row) {
      this.$confirm('此操作将删除此用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$http.delete('/nktdec/cruserinfo/cruserinfo/' + row.id)
        console.log(res)
        if (res.data.code === '1') {
          this.$message.success('删除成功！')
          this.getUserList()
        }
      })
    },
    async onSubmit() {
      console.log(this.seachForm)
      const arr = []
      arr[0] = (this.seachForm.userName.length === 0 ? '' : 'userName=' + this.seachForm.userName)
      arr[1] = (this.seachForm.cellphone.length === 0 ? '' : 'cellphone=' + this.seachForm.cellphone)
      arr[2] = (this.seachForm.userType.length === 0 ? '' : 'userType=' + this.seachForm.userType)
      console.log(arr)
      const tmparr = arr.filter(item => item.trim() !== '')
      if (tmparr.length !== 0) {
        const str = '?' + tmparr.join('&')
        console.log(str)
        const res = await this.$http.get('/nktdec/cruserinfo/cruserinfos' + str)
        // console.log(res)
        this.tableData = res.data
      }
    },
    clearall() {
      this.seachForm.userName = ''
      this.seachForm.cellphone = ''
      this.seachForm.userType = ''
      this.getUserList()
    },
    async getUserList() {
      const res = await this.$http.get('/nktdec/cruserinfo/cruserinfos')
      console.log(res)
      this.tableData = res.data
    },
    adduser() {
      this.$router.push('/adduser/true')
    },
    async getUserType() {
      const res = await this.$http.get('/nktdec/cruserinfo/usertypes')
      console.log(res.data)
      this.option = res.data
    },
  }
}
</script>
<style scoped>
.lineform {
  display: flex;
  justify-content: flex-start;
}
.expendform {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.expendform .el-form-item {
  width: 48%;
}
</style>
