<template>
  <div style="text-align: left">编辑用户</div>
  <el-form
    :model="edituser"
    :rules="rules"
    ref="ruleForm"
    label-width="100px"
    style="margin-top: 30px"
    class="demo-ruleForm"
  >
    <el-form-item label="用户姓名" prop="userName" style="width: 50%">
      <div class="displayflex">
        <el-input
          v-model="edituser.userName"
          clearable
          placeholder="请输入用户姓名"
          style="width: 200px"
        ></el-input>
      </div>
    </el-form-item>
    <el-form-item label="登录名" prop="loginId" style="width: 50%">
      <div class="displayflex">
        <el-input
          v-model="edituser.loginId"
          clearable
          placeholder="请输入登录名"
          style="width: 200px"
        ></el-input>
      </div>
    </el-form-item>
    <el-form-item label="手机号码" prop="cellphone" style="width: 50%">
      <div class="displayflex">
        <el-input
          v-model="edituser.cellphone"
          clearable
          placeholder="请输入手机号码"
          style="width: 200px"
        ></el-input>
      </div>
    </el-form-item>
    <el-form-item label="用户类型" prop="userType" style="width: 50%">
      <div class="displayflex">
        <el-select
          v-model="edituser.userType"
          clearable
          placeholder="请选择"
          style="width: 200px"
        >
          <el-option
            v-for="item in option"
            :key="item.type"
            :label="item.value"
            :value="item.name"
          >
          </el-option>
        </el-select>
      </div>
    </el-form-item>
  </el-form>
  <div class="displayflex">
    <el-button
      type="primary"
      size="mini"
      style="margin-left: 30px"
      @click="submituserinfo"
      >提交</el-button
    >
  </div>
</template>
<script>
export default {
  data() {
    return {
      edituser: {
        userName: '',
        loginId: '',
        userType: '',
        cellphone: ''
      },
      option: []
    }
  },
  methods: {
    async getUserType() {
      const res = await this.$http.get('/nktdec/cruserinfo/usertypes')
      console.log(res.data)
      this.option = res.data
    },
    async submituserinfo() {
      if (this.$route.params === 'true') {
        const res = await this.$http.post('/nktdec/cruserinfo/cruserinfos', {
          userName: this.edituser.userName,
          loginId: this.edituser.loginId,
          userType: this.edituser.userType,
          cellphone: this.edituser.cellphone,
        })
        console.log(res)
        this.$alert('该用户密码为' + res.data.result, '编辑成功', {
          confirmButtonText: '确定',
          callback: () => {
            this.$router.push('/systommange')
          }
        })
      } else {
        const res = await this.$http.post('/nktdec/cruserinfo/cruserinfos', {
          id: this.edituser.id,
          userName: this.edituser.userName,
          loginId: this.edituser.loginId,
          userType: this.edituser.userType,
          cellphone: this.edituser.cellphone,
        })
        console.log(res)
        if (res.data.code === '1') {
          this.$alert('该用户密码为' + res.data.result, '编辑成功', {
            confirmButtonText: '确定',
            callback: () => {
              this.$router.push('/systommange')
            }
          })
        } else {
          this.$message.error(res.data.message)
        }
      }
    }
  },
  computed: {
    UserInfo() {
      return this.$store.state.UserInfo
    }
  },
  created() {
    const Arr = [
      {
        name: '首页',
        index: '/index'
      },
      {
        name: '系统管理',
        index: '/systommange'
      },
      {
        name: '用户列表',
        index: '/systommange'
      }, {
        name: '编辑用户',
        index: ''
      }]
    this.$store.commit('setBreadInfo', Arr)
    this.getUserType()
    if (this.$route.params.isadd === 'false') {
      this.edituser = this.UserInfo
    }
    console.log(this.edituser)
  },
}
</script>
<style scoped>
</style>
